<template>
  <v-app-bar app light color="white" class="position-index">
    <div class="d-flex align-left pl-7">
      <v-img contain :src="require('@/assets/logo.png')" transition="scale-transition" width="75"/>
    </div>
    <v-spacer></v-spacer>
    <v-menu v-if="$vuetify.breakpoint.smAndDown" bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="item in navItems" :key="item.id" @click="$vuetify.goTo(item.id, options)">
          <v-list-item-title>
            <v-btn text :color="item.color">{{item.title}}</v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div class="d-none d-md-block">
      <v-btn v-for="item in navItems" :key="item.id" text :color="item.color" @click="$vuetify.goTo(item.id, options)">{{item.title}}</v-btn>
    </div>
  </v-app-bar>
</template>

<script>
  import * as easings from 'vuetify/es5/services/goto/easing-patterns'

  export default {
    name: 'Navbar',
    data() {
      return {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
        easings: Object.keys(easings),
        navItems: [
          { id: '#home', title: 'Home', color: 'funfitPink' },
          { id: '#classes', title: 'Classes', color: 'funfitPurple' },
          { id: '#testimonials', title: 'Testimonials', color: 'funfitBlue' },
          { id: '#trainers', title: 'Meet the Trainers', color: 'funfitYellow' },
          { id: '#origins', title: 'Where it Started', color: 'funfitOrange' },
          { id: '#contact', title: 'Contact us', color: 'funfitGray' }
        ]
      }
    },
    computed: {
      options () {
        return {
          duration: this.duration,
          offset: this.offset,
          easing: this.easing
        }
      }
    }
  }
</script>

<style>
  .position-index {
    z-index: 3;
  }
</style>
