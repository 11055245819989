import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import './scss/style.scss'
import { analytics } from './firebase'
import * as VueGooleMaps from 'vue2-google-maps'

Vue.prototype.$analytics = analytics

Vue.config.productionTip = false

Vue.use(VueGooleMaps, {
  load: { key: 'AIzaSyCffS3DzNXL_xzuIFkXZTybU9u68ZPG37w' }
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
