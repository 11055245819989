<template>
  <div>
    <div id="home">
      <div class="home-bg pa-10">
        <v-slide-x-transition>
          <div v-show="slideIn1">
            <h5 class="funfitGray--text">Towards a</h5>
            <h1 class="funfitPurple--text">HEALTHY</h1>
            <h1 class="funfitPink--text">FAMILY</h1>
          </div>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <div v-show="slideIn2">
            <h5 class="funfitGray--text mt-10">Through</h5>
            <h1 class="funfitBlue--text">FUN &</h1>
            <h1 class="funfitYellow--text">FITNESS</h1>
          </div>
        </v-slide-x-transition>
      </div>
      <v-slide-x-transition>
        <div v-if="$vuetify.breakpoint.xsOnly" v-show="slideIn3" class="mobile-bg"></div>
      </v-slide-x-transition>
      <v-row v-if="$vuetify.breakpoint.smAndUp" class="white--text">
        <v-col v-for="item in bannerItems" :key="item.title" cols="12" sm="6" md="3" class="about-bg pa-10" :class="item.bgColor">
          <h1>{{item.title}}</h1>
          <p class="mt-10">{{item.topContent}}<br><br>{{item.bottomContent}}</p>
          <a v-if="item.cta" :href="item.cta" class="white--text">Click here to find out more!</a>
        </v-col>
      </v-row>
      <v-carousel v-if="$vuetify.breakpoint.xsOnly" height="500" class="white--text" hide-delimiter-background :show-arrows="false">
        <v-carousel-item v-for="item in bannerItems" :key="item.title">
          <v-sheet height="100%" :class="item.bgColor">
            <div class="about-bg pa-10">
              <h1>{{item.title}}</h1>
              <p class="mt-10">{{item.topContent}}<br><br>{{item.bottomContent}}</p>
              <a v-if="item.cta" :href="item.cta" class="white--text">Click here to find out more!</a>
            </div>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </div>

    <div id="classes">
      <h1 class="funfitBlue--text text-center my-10 py-5">Have a look at our<br v-if="$vuetify.breakpoint.xsOnly"> available classes!</h1>
      <v-row v-if="$vuetify.breakpoint.mdAndUp" class="px-10 text-center pb-10 mb-10">
        <v-col v-for="item in classItems" :key="item.level" cols="12" sm="3">
          <v-card class="white--text" :class="item.bgColor">
            <v-card-title>{{item.title}}</v-card-title>
            <v-card-text>
              <h1 class="level">level.<span class="level-numbering">{{item.level}}</span></h1>
              <p class="white--text">{{item.topContent}}<br><br>{{item.bottomContent}}</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <p class="px-10">with coach {{item.coach}}</p>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-carousel v-if="$vuetify.breakpoint.smAndDown" height="auto" light class="white--text text-center mb-10" hide-delimiter-background :show-arrows="false">
        <v-carousel-item v-for="item in classItems" :key="item.level" class="px-10 pb-10">
          <v-card class="white--text mb-5" :class="item.bgColor">
            <v-card-title>{{item.title}}</v-card-title>
            <v-card-text>
              <h1 class="level">level.<span class="level-numbering">{{item.level}}</span></h1>
              <p class="white--text">{{item.topContent}}<br><br>{{item.bottomContent}}</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <p class="px-10">with coach {{item.coach}}</p>
            </v-card-actions>
          </v-card>
        </v-carousel-item>
      </v-carousel>
    </div>

    <div id="testimonials">
      <div class="testimonial-bg pa-10">
        <h1 class="white--text text-center my-5">What are people saying</h1>
        <v-carousel v-if="$vuetify.breakpoint.mdAndUp" height="auto" hide-delimiters>
          <v-carousel-item>
            <v-row class="funfitGray--text pa-10 mx-10">
              <v-col cols="12" class="white--bg pa-10">
                <p>When I first started trampoline jumping, I was afraid that it will be difficult especially with my old age. However, with enough encouragement from the coaches, I found my confidence and now really enjoy my weekly classes with them! Thank you for being inclusive Funfit!</p>
                <h5 class="mt-10"><b>Hjh Sopiah</b></h5>
                <p>Trampoline freshie</p>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
        <v-carousel v-if="$vuetify.breakpoint.smAndDown" height="auto" hide-delimiter-background :show-arrows="false">
          <v-carousel-item class="mb-10">
            <div class="funfitGray--text white--bg pa-5 my-5">
              <p>When I first started trampoline jumping, I was afraid that it will be difficult especially with my old age. However, with enoough encouragement from the coaches, I found my confidence and now really enjoy my weekyl classes with them! Thank you for being inclusive Funfit!</p>
              <h5 class="mt-10"><b>Hjh Sopiah</b></h5>
              <p>Trampoline freshie</p>
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>

    <div id="trainers">
      <h1 class="funfitBlue--text text-center my-10 py-5">Meet your instructors!</h1>
      <v-row class="px-10 pb-10 mb-10">
        <v-col v-for="item in trainerItems" :key="item.name" cols="12" sm="6" md="3">
          <v-card flat>
            <v-img :src="require(`@/assets/${item.image}.jpg`)" height="400"></v-img>
            <v-card-title :class="item.textColor"><h1>{{item.name}}</h1></v-card-title>
            <v-card-text>
              <p class="funfitGray--text">{{item.description}}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div id="origins">
      <div class="origins-bg white--text">
        <v-row>
          <v-col cols="12" sm="6" md="3" class="legacy-bg"></v-col>
          <v-col cols="12" sm="6" md="9" class="px-10 pr-15">
            <h1 class="my-10">Where it all started</h1>
            <p>We would always remember the two sides of our late Major Hj Rosli Hj Ahmad that made an incredible impact to our way of life.</p>
            <p>As a father, he's the very definition of a family man. Following his principle of "family always comes first", his supportive attitude in all our family's endeavors encourages us to be open and curious. He's also a prominent advocate for education and its importance in our daily lives - making it a mantra for him to remind us to never leave their studies behind no matter what.</p>
            <p>As a role model, he was the first person that introduced us to the world of fitness. Ranging from hiking, track running, karate, boxing and so on, he had instilled a sense of competitiveness into ourselves. He's never afraid to push us beyond our limits as he never found the need to - his version of fitness has always been fun, recreational and always comes with a foodie treat at the end of a good day's workout which we vey much enjoyed!</p>
            <p>In 2020, FunFit is founded to commemorate his philosophy of bringing together families and trainers for a good sweat out - just as he did with his own family for the past few decades. "In everything you do, always have fun!" - he would remind everyone as he believes the harmony of having fun while keeping fit.</p>
          </v-col>
        </v-row>
        <v-row class="pa-10">
          <v-col cols="12" sm="6" md="7" class="px-10 mb-10 instagram-section">
            <v-icon color="white" large>mdi-instagram</v-icon>
            <h1>Keep up with our latest updates <br>on our Instagram page!</h1>
          </v-col>
          <v-col cols="12" sm="6" md="5" class="instagram-carousel">
            <div class="instagram-header pa-3 d-flex">
              <v-icon color="white" medium class="pr-2 pt-1">mdi-instagram</v-icon>
              <h5>Funfitr.studio</h5>
              <v-spacer></v-spacer>
              <a href="https://www.instagram.com/funfitr.studio/" target="_blank" class="white--text d-flex">
                <h5>Follow</h5>
                <v-icon color="white" medium class="pl-2 pt-1">mdi-plus-circle-outline</v-icon>
              </a>
            </div>
            <v-carousel height="auto" light class="white--text text-center mb-10 instagram-pictures" hide-delimiters hide-delimiter-background cycle :show-arrows="false">
              <v-carousel-item v-for="index in 5" :key="index">
                <v-card height="350" :class="`bg-${index}-instagram`"></v-card>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </div>
    </div>

    <div id="contact">
      <v-row>
        <v-col cols="12" sm="6" class="contact-bg white--text pa-10">
          <h1>Have any questions?</h1>
          <p>Feel free to contact us!</p>
          <v-form v-model="valid" class="mt-10">
            <v-text-field solo dense placeholder="Phone number" v-model="number" prefix="+673" :rules="numberRules" class="error-message"></v-text-field>
            <v-text-field solo dense placeholder="Name" v-model="name" :rules="nameRules" class="error-message"></v-text-field>
            <v-textarea solo dense placeholder="Message" v-model="message" :rules="messageRules" class="error-message"></v-textarea>
            <div class="text-center">
              <v-btn outlined color="white" class="px-15 mt-3" :disabled="!valid" @click="sendMessage()">Submit</v-btn>
            </div>
          </v-form>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <GmapMap
            :center="{lat: 4.943527432222967, lng: 114.94603221217729}"
            :zoom="18"
            map-type-id="roadmap"
            class="google-maps">
            <GmapMarker
              :position="{lat: 4.942654692187979, lng: 114.94590016615888}"
              :clickable="true"/>
          </GmapMap>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" class="contact-hours">
          <p>OUR WORKING HOURS</p>
          <p>Monday - Saturday <br> **Time depends on scheduled availability**</p>
        </v-col>
        <v-col cols="12" sm="6" class="contact-details">
          <p>CONTACT US</p>
          <div class="d-flex">
            <v-icon class="pr-5 funfitPurple--text">mdi-map-marker</v-icon>
            <p class="my-2">30.1, 1st Floor, Kg Delima Jaya, Simpang 62 Kg, Jln Muara, BB4713</p>
          </div>
          <div class="d-flex">
            <v-icon class="pr-5 funfitPurple--text">mdi-email</v-icon>
            <p class="my-2">funfitr.studio@gmail.com</p>
          </div>
          <div class="d-flex">
            <v-icon class="pr-5 funfitPurple--text">mdi-cellphone-android</v-icon>
            <p class="my-2">+673 871 4050</p>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    data() {
      return {
        slideIn1: false,
        slideIn2: false,
        slideIn3: false,
        valid: false,
        serviceNumber: 6737289041,
        number: '',
        numberRules: [
          number => !!number || 'Please enter your number'
        ],
        name: '',
        nameRules: [
          name => !!name || 'Please enter your name'
        ],
        message: '',
        messageRules: [
          message => !!message || 'Please enter your message'
        ],
        bannerItems: [
          {
            title: 'New to rebound trampo?',
            topContent: 'Rebound trampo is a low impact exercise performed on a "rebound" or a "mini-trampoline".',
            bottomContent: 'Some basic movements and actions include bouncing, jumping jacks, twists, side to side motions, running in place, dance movements, and a wide variety of fun and intense movements!',
            bgColor: 'bg-pink-banner'
          },
          {
            title: 'What are the benefits of trampo?',
            topContent: 'Apart from increasing muscular strength, losing weight and improving your posture, it also boosts your immune system, your concentration power, energy, balance and coordination!',
            cta: 'https://www.instagram.com/p/CCaKnVHDzS4/',
            bgColor: 'bg-purple-banner'
          },
          {
            title: 'What to prepare before classes?',
            topContent: "Be sure to come dressed in your most comfortable sports attire! We'll be doing a lot of sweating and moving about, so a nice towel would be great to have handy.",
            bottomContent: 'We encourage attendees to bring their own bottled water for replenishments!',
            bgColor: 'bg-blue-banner'
          },
          {
            title: 'How to contact us at FunFit?',
            topContent: 'You can text us via WhatsApp at 8714050 for booking a class. Give us a ring or fill in the contact form below if you have further questions!',
            bottomContent: 'We hope to see you join our session and have fun + fit together!',
            bgColor: 'bg-orange-banner'
          }
        ],
        classItems: [
          {
            title: 'Gentle Trampo',
            level: 1,
            topContent: 'Try our Gentle Trampo class to work your heart and body enough to burn some calories yet gentle enough to have fun and unwind!',
            bottomContent: 'Suitable for beginners and those who prefer low intensity cardio trampo workout.',
            coach: 'Dr. Asmah',
            bgColor: 'bg-pink-class'
          },
          {
            title: 'Beginner Trampo',
            level: 2,
            topContent: 'First timer and want to have a feel of what trampo exercises is all about?',
            bottomContent: 'Get your cardio fix with our Beginner Trampo classes with our very own coach Liah as a great introduction to learn the basics of bounce!',
            coach: 'Liah',
            bgColor: 'bg-purple-class'
          },
          {
            title: 'KPop+ Trampo',
            level: 3,
            topContent: 'Bounce with us at KPop+ Trampo to an upbeat mix of KPop tunes to keep that heartbeat up!',
            bottomContent: 'The class is for beginner to intermediate level but everyone is welcome to go at their own pace and most importantly - to HAVE FUN!',
            coach: 'Marsya',
            bgColor: 'bg-blue-class'
          },
          {
            title: 'Cardio Trampo',
            level: 4,
            topContent: 'Challenge yourself with our high intensity cardio trampo!',
            bottomContent: 'This is a high intensity but low impact workout to improve overall health. Jump to the tune of upbeat tracks, cool moves and of course a good sweat!',
            coach: 'Syura',
            bgColor: 'bg-orange-class'
          }
        ],
        trainerItems: [
          {
            name: 'Dr. Asmah',
            description: 'As a trainer focusing on healthy aging for the elderly, Dr. Asmah wishes that more people aspire to be physically independent even after 100 years old!',
            textColor: 'funfitPink--text',
            image: 'asmah'
          },
          {
            name: 'Liah',
            description: "You will want Liah to teach you the basics of trampo. She's fun and energetic but maintains a slower pace, perfect to match beginners one step at a time.",
            textColor: 'funfitPurple--text',
            image: 'liah'
          },
          {
            name: 'Marsya',
            description: 'An avid KPop fan herself, Marsya puts her special twist of intensity into her regular trampo classes. For KPop lovers, highly recommend to follow this energetic coach!',
            textColor: 'funfitBlue--text',
            image: 'marsya'
          },
          {
            name: 'Syura',
            description: 'Syura puts an emphasis on pushing people beyond their limits. Her classes are boosted with powerful movements and perfect for those looking for a good challenge.',
            textColor: 'funfitOrange--text',
            image: 'syura'
          }
        ]
      }
    },
    mounted() {
      this.slideXTransition('slideIn1', 100)
      this.slideXTransition('slideIn2', 300)
      this.slideXTransition('slideIn3', 500)
    },
    methods: {
      slideXTransition(property, duration) {
        setTimeout(() => {
          switch (property) {
            case 'slideIn1':
              this.slideIn1 = true
              break
            case 'slideIn2':
              this.slideIn2 = true
              break
            case 'slideIn3':
              this.slideIn3 = true
              break
          }
        }, duration)
      },
      sendMessage() {
        this.errors = {
          name: [],
          number: [],
          message: []
        }
        this.loading = true
        const payload = this.constructMessage()

        window.open(`https://api.whatsapp.com/send?phone=${this.serviceNumber}&text=${encodeURIComponent(payload)}`)
      },
      constructMessage() {
        const message =
          `Hello there! I'm ${this.name} 😁\n\n` +
          `${this.message}\n\n` +
          `Here is my contact number so you can reach me : ${this.number}`

        return message
      }
    }
  }
</script>
