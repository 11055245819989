import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        funfitPurple: '#886dba',
        funfitPink: '#d25998',
        funfitYellow: '#fbeb30',
        funfitBlue: '#246eb6',
        funfitOrange: '#f1936f',
        funfitGray: '#878787'
      }
    }
  }
});
