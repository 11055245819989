// Do not overwrite this file when in different branches

import firebase from 'firebase/app'
import "firebase/analytics"
import "firebase/performance"

const firebaseConfig = {
  apiKey: "AIzaSyBcppp9-2SiAOc8sz8BjK_rbnce_Rh_i18",
  authDomain: "funfit-d37d4.firebaseapp.com",
  projectId: "funfit-d37d4",
  storageBucket: "funfit-d37d4.appspot.com",
  messagingSenderId: "870553030211",
  appId: "1:870553030211:web:65bcc38773e740ae218ef6",
  measurementId: "G-W8NJ2NW80X"
};
firebase.initializeApp(firebaseConfig)

const analytics = firebase.analytics()
const performance = firebase.performance()

export {
  firebase,
  analytics,
  performance
}
