<template>
  <v-footer color="funfitPurple">
    <v-row justify="center" no-gutters>
      <v-col
        class="primaryLight py-4 text-center white--text"
        cols="12">
        {{ new Date().getFullYear() }} and beyond <br class="d-sm-none"> — <br class="d-sm-none"> <strong>FunFitR Studio</strong> is powered by <strong>SynapseBN</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer'
  }
</script>
